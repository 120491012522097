import { renderIcon as C } from "../icon.renderer.js";
const H = "folder-open",
  L = ["folder-open", C({
    outline: '<path d="M33.26 13C33.84 12.99 34.38 13.26 34.71 13.74C35 14.16 35.08 14.69 34.92 15.18L30.73 30.28C30.6 30.72 30.19 31.02 29.73 31H4.41C3.66 31.02 3.03 30.44 3 29.69V7C3 5.9 3.9 5 5 5H11.49C12.13 5 12.73 5.3 13.11 5.82L15.42 9H29.39C30.49 9 31.39 9.9 31.39 11H14.91C14.59 11 14.29 10.85 14.1 10.59L11.49 7H5V25L7.92 14.66C7.92 14.66 7.92 14.57 7.92 14.52C8.32 13.63 9.19 13.04 10.17 13H33.26ZM5.9 29H28.94L32.88 14.95H10.16C10.01 14.98 9.87 15.07 9.78 15.19L5.9 29Z"/>',
    outlineAlerted: '<path d="M27.9041 1.64621L22.2224 11.1159C21.9529 11.4984 21.9284 11.9949 22.1591 12.4005C22.3898 12.806 22.8366 13.0519 23.315 13.0367H34.6884C35.1669 13.0519 35.6136 12.806 35.8443 12.4005C36.075 11.9949 36.0506 11.4984 35.781 11.1159L30.0993 1.64621C29.8713 1.26913 29.4535 1.03735 29.0017 1.03735C28.55 1.03735 28.1321 1.26913 27.9041 1.64621Z"/><path d="M20.1058 11H14.9102C14.5902 11 14.2902 10.85 14.1002 10.59L11.4902 7H5.00024V25L7.92024 14.66V14.52C8.32024 13.63 9.19024 13.04 10.1702 13H20.2327C20.2861 13.1326 20.3488 13.2627 20.4208 13.3893C20.8815 14.1991 21.6527 14.7472 22.5183 14.95H10.1602C10.0102 14.98 9.87024 15.07 9.78024 15.19L5.90024 29H28.9402L32.8559 15.0367H34.6639C34.7636 15.0387 34.863 15.0362 34.9617 15.0292C34.9503 15.0798 34.9365 15.1301 34.9202 15.18L30.7302 30.28C30.6002 30.72 30.1902 31.02 29.7302 31H4.41024C3.66024 31.02 3.03024 30.44 3.00024 29.69V7C3.00024 5.9 3.90024 5 5.00024 5H11.4902C12.1302 5 12.7302 5.3 13.1102 5.82L15.4202 9H21.1597L20.5364 10.0387C20.3378 10.3381 20.1939 10.6632 20.1058 11Z"/>',
    outlineBadged: '<path d="M31 11C33.7614 11 36 8.76142 36 6C36 3.23858 33.7614 1 31 1C28.2386 1 26 3.23858 26 6C26 8.76142 28.2386 11 31 11Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M26.101 11H14.91C14.59 11 14.29 10.85 14.1 10.59L11.49 7H5V25L7.92 14.66V14.52C8.32 13.63 9.19 13.04 10.17 13H33.26C33.84 12.99 34.38 13.26 34.71 13.74C35 14.16 35.08 14.69 34.92 15.18L30.73 30.28C30.6 30.72 30.19 31.02 29.73 31H4.41C3.66 31.02 3.03 30.44 3 29.69V7C3 5.9 3.9 5 5 5H11.49C12.13 5 12.73 5.3 13.11 5.82L15.42 9H24.6736C25.0297 9.7496 25.5151 10.4259 26.101 11ZM28.94 29H5.9L9.78 15.19C9.87 15.07 10.01 14.98 10.16 14.95H32.88L28.94 29Z"/>',
    solid: '<path d="M34.7058 13.74C34.3744 13.2643 33.8223 12.986 33.2379 13H10.2583C9.26952 13.0414 8.38858 13.6293 7.9806 14.52C7.97729 14.5666 7.97729 14.6134 7.9806 14.66L5.02463 25V7.00001H11.5946L14.2367 10.59C14.4279 10.8484 14.7327 11.0008 15.0567 11H31.3449C31.3449 9.89544 30.4384 9.00001 29.3203 9.00001H15.573L13.2345 5.82001C12.8522 5.30318 12.2426 4.99835 11.5946 5.00001H5.02463C3.90646 5.00001 3 5.89544 3 7.00001V29.69C3.03289 30.4389 3.66891 31.0226 4.42737 31H29.6644C30.1293 31.0183 30.5469 30.7213 30.6768 30.28L34.9184 15.18C35.0788 14.6935 35.0001 14.161 34.7058 13.74Z"/>',
    solidAlerted: '<path d="M27.9041 1.64621L22.2224 11.1159C21.9529 11.4984 21.9284 11.9949 22.1591 12.4005C22.3898 12.806 22.8366 13.0519 23.315 13.0367H34.6884C35.1669 13.0519 35.6136 12.806 35.8443 12.4005C36.075 11.9949 36.0506 11.4984 35.781 11.1159L30.0993 1.64621C29.8713 1.26913 29.4535 1.03735 29.0017 1.03735C28.55 1.03735 28.1321 1.26913 27.9041 1.64621Z"/><path d="M20.2325 13C20.2858 13.1326 20.3485 13.2627 20.4206 13.3893C21.0233 14.4489 22.1577 15.0604 23.3395 15.0367H34.6637C34.7629 15.0387 34.8618 15.0362 34.9601 15.0293C34.9487 15.0799 34.9348 15.1302 34.9184 15.18L30.6768 30.28C30.5469 30.7213 30.1293 31.0183 29.6644 31H4.42737C3.66891 31.0226 3.03289 30.4389 3 29.69V7.00001C3 5.89544 3.90646 5.00001 5.02463 5.00001H11.5946C12.2426 4.99835 12.8522 5.30318 13.2345 5.82001L15.573 9.00001H21.1594L20.5362 10.0387C20.3375 10.3381 20.1937 10.6632 20.1056 11H15.0567C14.7327 11.0008 14.4279 10.8484 14.2367 10.59L11.5946 7.00001H5.02463V25L7.9806 14.66C7.97729 14.6134 7.97729 14.5666 7.9806 14.52C8.38858 13.6293 9.26952 13.0414 10.2583 13H20.2325Z"/>',
    solidBadged: '<path d="M31 11C33.7614 11 36 8.76142 36 6C36 3.23858 33.7614 1 31 1C28.2386 1 26 3.23858 26 6C26 8.76142 28.2386 11 31 11Z"/><path d="M24.6736 9.00001C25.0297 9.74961 25.5151 10.4259 26.101 11H15.0567C14.7327 11.0008 14.4279 10.8484 14.2367 10.59L11.5946 7.00001H5.02463V25L7.9806 14.66C7.97729 14.6134 7.97729 14.5666 7.9806 14.52C8.38858 13.6293 9.26952 13.0414 10.2583 13H33.2379C33.8223 12.986 34.3744 13.2643 34.7058 13.74C35.0001 14.161 35.0788 14.6935 34.9184 15.18L30.6768 30.28C30.5469 30.7213 30.1293 31.0183 29.6644 31H4.42737C3.66891 31.0226 3.03289 30.4389 3 29.69V7.00001C3 5.89544 3.90646 5.00001 5.02463 5.00001H11.5946C12.2426 4.99835 12.8522 5.30318 13.2345 5.82001L15.573 9.00001H24.6736Z"/>'
  })];
export { L as folderOpenIcon, H as folderOpenIconName };
